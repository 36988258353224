<template>
  <!-- 素材中心修改页面 -->
  <el-main>
    <div class="classTree" ref="classTree">
      <TreeList
        :treeData="classData"
        :defaultProps="defaultProps"
        :first="true"
        @clickNodeLabel="clickNodeLabel"
        :nowChooseClassId="nowChooseClassId"
        @getImgClass="getImgClass"
        @deleteDeal="deleteDeal"
        @reNameThenDeal="reNameThenDeal"
        @successAddGroup="successAddGroup"
      ></TreeList>
    </div>
    <div class="resources">
      <div class="resourcesTop">
        <div class="tLeft">
          <el-button type="primary" size="small" @click="addGroup">添加分类</el-button>
          <el-button type="primary" size="small" @click="uploadImgBtn">上传图片</el-button>
          <el-button type="primary" size="small" @click="uploadVideoBtn">上传视频</el-button>
        </div>
        <div class="tRight">
          <div class="searchItem">
            <p class="title">关键词：</p>
            <el-input size="small" v-model="searchInfo.keyWord"></el-input>
          </div>
          <div class="searchItem">
            <p class="title">上传时间：</p>
            <el-date-picker
              size="small"
              v-model="searchInfo.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <el-button type="primary" size="small" @click="getClassContent(-1, 1)"> 搜 索 </el-button>
          <el-button size="small" @click="clearSearch"> 清 空 </el-button>
        </div>
      </div>
      <div class="resourcesMid">
        <div class="mLeft">
          <el-checkbox @change="isAllChoose" v-model="allChoose">全选</el-checkbox>
          <div>已选择{{ chooseList.length }}个文件/文件夹</div>
          <el-button v-show="chooseList.length > 0" size="small" @click="moveGroup"
            >移动到分类</el-button
          >
          <el-button v-show="chooseList.length > 0" size="small" @click="deleteImage(1)"
            >删 除</el-button
          >
        </div>
        <div class="mRight">
          <span class="capacity" style="margin-right: 20px"
            >容量：{{ use_capacity }} / {{ total_capacity }} GB</span
          >
          <el-select size="small" v-model="sortStyle" placeholder="请选择" @change="isLookImageFn">
            <el-option
              v-for="item in sortStyleList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <el-switch @change="isLookImageFn(1)" v-model="onlyImage" active-text="只显示图片">
          </el-switch>
          <el-switch @change="isLookImageFn(2)" v-model="onlyVideo" active-text="只显示视频">
          </el-switch>
        </div>
      </div>
      <div class="resourcesBot" @scroll="onLoadNextRow($event)">
        <No v-if="classContentList.length === 0"></No>
        <div>
          <div v-for="(item, index) in classContentList" :key="index" class="groupBox">
            <div v-if="item.type == 'group'" class="groupItem">
              <div
                class="top"
                @click="checkFlagFn(item, index)"
                @dblclick="enterGroup(item)"
                :style="{ borderColor: item.checkflag ? '#409EFF' : '#f2f3f5' }"
              >
                <img src="@/assets/image/wenjianjiaB.png" alt="" />
              </div>
              <div class="bot">
                <el-checkbox
                  @change="(val) => changeChooseStatus(val, item)"
                  v-model="item.checkflag"
                  @click.stop.native="() => {}"
                ></el-checkbox>
                <div
                  v-show="!item.updateNameFlag || !item.checkflag"
                  class="groupName"
                  @click.stop="updateName(item, index)"
                >
                  {{ item.name }}
                </div>
                <input
                  class="updateInputBox"
                  type="text"
                  @click.stop="() => {}"
                  ref="updateInput"
                  size="small"
                  v-show="item.updateNameFlag && item.checkflag"
                  :placeholder="item.name"
                  v-model="item.updateName"
                />
              </div>
            </div>
            <div v-if="item.type == 'image'" class="groupItem imageBox">
              <div
                class="top"
                @click="checkFlagFn(item, index)"
                :style="{ borderColor: item.checkflag ? '#409EFF' : '#f2f3f5' }"
              >
                <img class="img" :src="item.url" alt="" />
                <div class="mask">
                  <div @click.stop="lookCustomaryImage(item)">
                    <i class="el-icon-zoom-in"></i>
                    <span>预览</span>
                  </div>
                  <div @click.stop="deleteImage(0, item, index)">
                    <i class="el-icon-delete"></i>
                    <span>删除</span>
                  </div>
                </div>
              </div>
              <div class="bot">
                <el-checkbox
                  @change="(val) => changeChooseStatus(val, item)"
                  v-model="item.checkflag"
                  @click.stop.native="() => {}"
                ></el-checkbox>
                <div
                  v-show="!item.updateNameFlag || !item.checkflag"
                  class="groupName"
                  @click.stop="updateName(item, index)"
                >
                  {{ item.name }}
                </div>
                <input
                  class="updateInputBox"
                  type="text"
                  @click.stop="() => {}"
                  ref="updateInput"
                  size="small"
                  v-show="item.updateNameFlag && item.checkflag"
                  v-model="item.updateName"
                  :placeholder="item.name"
                />
              </div>
            </div>
            <div v-if="item.type == 'video'" class="groupItem videoBox">
              <div
                class="top"
                @click="checkFlagFn(item, index)"
                :style="{ borderColor: item.checkflag ? '#409EFF' : '#f2f3f5' }"
              >
                <img v-if="item.cover" :src="item.cover" alt="" />
                <video
                  @mouseenter="playVideo($event)"
                  @mouseleave="suspendVideo($event)"
                  v-else
                  :src="item.url"
                  muted
                  loop
                ></video>
              </div>
              <div class="bot">
                <el-checkbox
                  @change="(val) => changeChooseStatus(val, item)"
                  v-model="item.checkflag"
                  @click.stop.native="() => {}"
                ></el-checkbox>
                <div
                  v-show="!item.updateNameFlag || !item.checkflag"
                  class="groupName"
                  @click.stop="updateName(item, index)"
                >
                  {{ item.name }}
                </div>
                <input
                  class="updateInputBox"
                  type="text"
                  @click.stop="() => {}"
                  ref="updateInput"
                  size="small"
                  v-show="item.updateNameFlag && item.checkflag"
                  v-model="item.updateName"
                  :placeholder="item.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="lookBigImgInfoFlag">
      <div class="imgBox">
        <img :src="imgList[lookBigImgInfoIndex].url" alt="" />
        <i class="el-icon-back left bg3" @click="changeImg(0)"></i>
        <i class="el-icon-right right bg3" @click="changeImg(1)"></i>
      </div>
      <i class="el-icon-circle-close close" @click="lookBigImgInfoFlag = false"></i>
    </div>
    <el-dialog title="上传图片" :visible.sync="uploadImg" width="800px" @close="isLookImageFn">
      <UploadImg
        v-if="uploadImg"
        @chooseSuccess="chooseSuccess"
        :nowChooseClassId="nowChooseClassId"
        :data="classData"
        ref="UploadImg"
      ></UploadImg>
    </el-dialog>
    <!-- 添加分组弹框 -->
    <el-dialog
      title="添加分组"
      :visible.sync="addGroupDialog"
      width="600px"
      @close="addGroupDialogClose"
    >
      <div class="addGroupBox">
        <div class="showPid">
          <div class="title">新建到：</div>
          <div class="groupRouter">
            <div class="routerItem" v-for="(item, index) in fatherGroup" :key="index">
              <div>{{ item.name }}</div>
              <div v-if="index != fatherGroup.length - 1">&nbsp; > &nbsp;</div>
            </div>
          </div>
          <div class="changePid">
            <el-button size="small" @click="chanegGroupDrawer">更改位置</el-button>
          </div>
        </div>
        <el-input placeholder="请输入分类名称" v-model="addGroupName"></el-input>
        <el-button type="primary" @click="addGroupFn">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 找寻分组的抽屉 -->
    <el-drawer
      :visible.sync="groupDrawer"
      :with-header="false"
      @closed="suc"
      :before-close="drawerHandleClose"
    >
      <div class="drawerBox">
        <FindGroup
          :styleD="{ width: '100%', height: '80%' }"
          v-if="groupDrawer && moveGroupOrAddGroup === 1"
          :data="classData"
          ref="FindGroup"
        ></FindGroup>
        <FindGroup
          :styleD="{ width: '100%', height: '80%' }"
          v-if="groupDrawer && moveGroupOrAddGroup === 0"
          :data="moveGroupList"
          ref="FindGroup"
        ></FindGroup>
        <div class="btn" @click="sureAddThis">确认添加到此文件夹</div>
      </div>
    </el-drawer>
    <UploadVideo
      ref="UploadVideo"
      :classData="classData"
      :showVideoDialog="addVideoDialog"
      :nowGroup="nowChooseClassId"
      @videoDialogClose="videoDialogClose"
      @uploadVideoSuccess="uploadVideoSuccess"
    ></UploadVideo>
  </el-main>
</template>

<script>
import TreeList from '@/components/treeList.vue'
import UploadImg from './sonDialog/upload.vue'
import FindGroup from '@/components/lookGroup.vue'
import UploadVideo from '@/components/uploadVideo.vue'

export default {
  components: {
    TreeList,
    UploadImg,
    FindGroup,
    UploadVideo
  },
  data() {
    return {
      classData: [],
      defaultProps: {
        children: '_child',
        label: 'name'
      },
      // 首次加载的高亮->id
      nowChooseClassId: { id: -1, name: '全部图片' },
      // 当前分类中正在修改名称的项
      nowItemUpdate: null,
      classContentList: [],
      // 被选中的所有项
      chooseList: [],
      allChoose: false,
      sortStyle: 0,
      sortStyleList: [
        { type: 0, name: '最新上传在前' },
        { type: 1, name: '最新上传在后' },
        { type: 2, name: '按图片名降序' },
        { type: 3, name: '按图片名升序' }
      ],
      onlyImage: false,
      onlyVideo: false,
      lookBigImgInfoFlag: false,
      lookBigImgInfoIndex: -1,
      searchInfo: {
        keyWord: '',
        time: []
      },
      page: 1,
      rows: 40,
      total: 0,
      uploadImg: false,
      // changeGroupDialog: false,
      chooseAddGroupP: null,
      // 将所有分组放到同一个层级种
      allGroupList: [],
      fatherGroup: [{ id: -1, name: '全部图片' }],
      addGroupDialog: false,
      addGroupName: '',
      groupDrawer: false,
      addVideoDialog: false,
      use_capacity: null,
      total_capacity: null,
      moveGroupOrAddGroup: 1,
      moveGroupList: [],
      isReNameIng: false
    }
  },
  created() {
    this.getImgClass()
    this.getClassContent(-1)
  },
  mounted() {
    document.addEventListener('click', this.documentFn)
    document.addEventListener('keyup', this.keyUpFn)
  },
  computed: {
    imgList() {
      return this.classContentList.filter((item) => item.type === 'image')
    },
    fisrtImgIndex() {
      let index = this.classContentList.indexOf((item) => item.type == 'image')
      let result = null
      if (index == -1) {
        result = this.classContentList
      } else {
        result = index
      }
      return result
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentFn, false)
  },
  methods: {
    playVideo($event) {
      $event.target.play()
    },
    suspendVideo($event) {
      $event.target.pause()
    },
    uploadVideoSuccess() {
      this.addVideoDialog = false
      this.isLookImageFn()
    },
    videoDialogClose() {
      this.addVideoDialog = false
    },
    // 点击上传视频按钮
    uploadVideoBtn() {
      this.addVideoDialog = true
      if (this.nowChooseClassId.id != -1) {
        this.$nextTick(() => {
          // 实现选中当前文件夹
          let arr = []
          this.fatherGroup.map((item) => {
            arr.push(item.id)
          })
          this.$refs.UploadVideo.videoUpload.groupClass = arr
        })
      }
    },
    sureAddThis() {
      if (!this.$refs.FindGroup.nowThis) {
        this.$message({
          message: '请选择素材分组',
          type: 'warning',
          customClass: 'mzindex'
        })
        return
      }
      if (this.$refs.FindGroup.nowThis.id == this.nowChooseClassId.id) {
        this.$message({
          message: '不可选择原来的分组',
          type: 'warning',
          customClass: 'mzindex'
        })
        this.$refs.FindGroup.nowThis = null
        this.$refs.FindGroup.setDefaule()
        return
      }
      let item = this.$refs.FindGroup.nowThis
      if (!this.moveGroupOrAddGroup) {
        //
        this.chooseAddGroupP = item
        this.sureMoveGroup()
      } else {
        if (item.pid == 0 || item.id == -1) {
          this.fatherGroup = [item]
        } else {
          this.fatherGroup = [item]
          this.findFather(item)
        }
      }

      this.groupDrawer = false
    },
    chanegGroupDrawer() {
      this.moveGroupOrAddGroup = 1
      this.groupDrawer = true
      this.$nextTick(() => {
        this.$refs.FindGroup.setDefaule(this.fatherGroup[this.fatherGroup.length - 1])
      })
    },
    moveGroup() {
      this.moveGroupOrAddGroup = 0
      this.moveGroupList = JSON.parse(JSON.stringify(this.classData))
      let groupList = this.chooseList.filter((item) => item.type === 'group')
      if (
        this.nowChooseClassId.id == -1 ||
        this.nowChooseClassId.id == -2 ||
        this.nowChooseClassId.id == 0
      ) {
        this.moveGroupList.splice(0, 2)
        groupList.map((item) => {
          let index = this.moveGroupList.findIndex((item1) => item1.id === item.id)
          this.moveGroupList.splice(index, 1)
        })
      } else {
        this.dealFnArr(this.moveGroupList, (item) => {
          if (item.id === this.nowChooseClassId.id) {
            let len = item._child.length
            for (let index = len - 1; index >= 0; index--) {
              let isDel = groupList.find((item1) => item1.id === item._child[index].id)
              if (isDel) {
                item._child.splice(index, 1)
              }
            }
          }
        })
      }

      this.groupDrawer = true
    },
    // 确定移动到分组
    sureMoveGroup() {
      let imgId = []
      let groupArr = []
      let groupIdArr = []
      let groupNameArr = []
      this.chooseList.map((item) => {
        if (item.type === 'group') {
          groupIdArr.push(item.id)
          groupNameArr.push(item.name)
          let res = JSON.parse(JSON.stringify(item))
          res.pid = this.chooseAddGroupP.id == -1 ? 0 : this.chooseAddGroupP.id
          groupArr.push(res)
        } else {
          imgId.push(item.id)
        }
      })
      if (groupIdArr.length == 0) {
        this.moveThenImg(imgId)
        this.allMoveSuccess()
        return
      }
      // 先移动文件夹
      this.$axios
        .post(this.$api.sourceCenter.moveGroup, {
          id: groupIdArr,
          pid: this.chooseAddGroupP.id == -1 ? 0 : this.chooseAddGroupP.id,
          name: groupNameArr
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('移动文件夹成功')
            this.dealGroupMove(groupArr)
            this.moveThenImg(imgId)
          } else if (res.code === -2) {
            let needDelArr = res.result
            this.$confirm(`${res.msg}`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$axios
                .post(this.$api.sourceCenter.moveGroup, {
                  id: groupIdArr,
                  pid: this.chooseAddGroupP.id == -1 ? 0 : this.chooseAddGroupP.id,
                  name: groupNameArr,
                  is_confirm: 1
                })
                .then((res) => {
                  if (res.code == 0) {
                    this.$message.success('移动文件夹成功')
                    this.dealGroupMove(groupArr, needDelArr)
                    this.moveThenImg(imgId)
                  } else {
                    this.$message.error(res.msg)
                  }
                })
                .catch()
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch()
    },
    // 移动分组后处理树状图
    dealGroupMove(groupArr, delArr) {
      if (this.nowChooseClassId.id === -1 || this.nowChooseClassId.id === 0) {
        groupArr.map((item) => {
          let index = this.classData.findIndex((item1) => item1.id === item)
          this.classData.splice(index, 1)
        })
      }
      this.dealFnArr(this.classData, (item) => {
        if (this.chooseAddGroupP.id != -1 && this.chooseAddGroupP.id !== 0) {
          // 将文件夹放到转移后的文件夹里面
          if (item.id === this.chooseAddGroupP.id) {
            item.sonIsOpen = true
            // 判断是否需要在目标文件夹删除重复文件夹
            if (delArr) {
              delArr.map((item3) => {
                if (item._child) {
                  let index = item._child.findIndex((item4) => item4.id == item3)
                  item._child.splice(index, 1)
                }
              })
            }
            if (item._child) {
              item._child.push(...groupArr)
            } else {
              this.$set(item, '_child', groupArr)
            }
          }
          // 将文件夹从此文件夹删除
          if (item.id === this.nowChooseClassId.id) {
            groupArr.map((item1) => {
              if (item._child) {
                let index = item._child.findIndex((item2) => item2.id === item1.id)
                item._child.splice(index, 1)
                if (item._child.length == 0) {
                  delete item._child
                }
              }
            })
          }
        }
      })
    },
    // 移动文件夹后移动图片
    moveThenImg(imgId) {
      if (!imgId.length) {
        this.allMoveSuccess()
        return
      }
      this.$axios
        .post(this.$api.sourceCenter.ImageMove, {
          id: imgId,
          group_id: this.chooseAddGroupP.id
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('图片移动成功')
            this.allMoveSuccess()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 所有移动接口调完后处理页面
    allMoveSuccess() {
      this.isLookImageFn()
      this.chooseList = []
      this.findAndOpen(this.chooseAddGroupP)
    },
    addGroupFn() {
      if (this.addGroupName == '') {
        this.$message.warning('文件夹名字不能为空')
        return
      }
      let obj = { name: this.addGroupName }
      let fatherItem = this.fatherGroup[this.fatherGroup.length - 1]
      obj.pid = fatherItem.id
      if (obj.pid == -2 || obj.pid == -1) {
        obj.pid = 0
      }
      this.$axios.post(this.$api.sourceCenter.ImagesGroupAdd, obj).then((res) => {
        if (res.code === 0) {
          this.$message.success('添加成功')
          this.addGroupDialog = false
          if (fatherItem.id == -1 || fatherItem.id == -2 || fatherItem.id == 0) {
            this.classData.push({
              id: res.result,
              name: obj.name,
              type: 'group',
              checkflag: this.allChoose,
              updateNameFlag: false,
              updateName: '',
              sonIsOpen: false,
              checked: false
            })
            this.allGroupList.push({
              id: res.result,
              name: obj.name,
              type: 'group',
              checkflag: this.allChoose,
              updateNameFlag: false,
              updateName: obj.name,
              sonIsOpen: false,
              checked: false
            })
          } else {
            this.dealFnArr(this.classData, (item) => {
              if (item.id === fatherItem.id) {
                this.findAndOpen(item)
                if (item._child) {
                  item._child.push({
                    id: res.result,
                    name: obj.name,
                    type: 'group',
                    checkflag: this.allChoose,
                    updateNameFlag: false,
                    updateName: obj.name,
                    pid: item.id,
                    sonIsOpen: false,
                    checked: false
                  })
                } else {
                  let arr = [
                    {
                      id: res.result,
                      name: obj.name,
                      type: 'group',
                      checkflag: this.allChoose,
                      updateNameFlag: false,
                      updateName: obj.name,
                      pid: item.id,
                      sonIsOpen: false,
                      checked: false
                    }
                  ]
                  this.$set(item, '_child', arr)
                }
                this.allGroupList.push({
                  id: res.result,
                  name: obj.name,
                  type: 'group',
                  checkflag: this.allChoose,
                  updateNameFlag: false,
                  updateName: obj.name,
                  pid: item.id,
                  sonIsOpen: false,
                  checked: false
                })
              }
            })
          }
          if (obj.pid == this.nowChooseClassId.id || !obj.pid) {
            this.isLookImageFn()
          }
          if (this.nowChooseClassId.pid == 0 || this.nowChooseClassId.id == -1) {
            this.fatherGroup = [this.nowChooseClassId]
          } else {
            this.findFather(this.nowChooseClassId)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 选中分类添加
    resultGroup(data) {
      this.chooseAddGroupP = data
    },
    // 点击添加分类 开启弹框
    addGroup() {
      this.addGroupDialog = true
    },
    addGroupDialogClose() {
      this.addGroupName = ''
    },
    //点击开启上传图片弹框
    uploadImgBtn() {
      this.uploadImg = true
      this.$nextTick(() => {
        this.$refs.UploadImg.findGroupFn()
      })
    },
    // 关闭选择图片的弹框
    chooseSuccess() {
      setTimeout(() => {
        this.uploadImg = false
      }, 1000)
    },
    documentFn() {
      if (this.nowItemUpdate) {
        this.sureUpdateName(this.nowItemUpdate)
      }
    },
    keyUpFn($event) {
      if ($event.keyCode == 13) {
        if (this.nowItemUpdate) {
          this.sureUpdateName(this.nowItemUpdate)
        }
      }
    },
    // 获取图片分组
    getImgClass() {
      let that = this
      this.$axios.post(this.$api.sourceCenter.ImagesGroupList).then((res) => {
        if (res.code == 0) {
          that.classData = res.result.list
          // 递归加入所需的标杆
          that.dealFnArr(that.classData, (item) => {
            this.$set(item, 'sonIsOpen', false)
            this.$set(item, 'checked', false)
            this.$set(item, 'updateNameFlag', false)
            this.$set(item, 'updateName', '')
            let res = JSON.parse(JSON.stringify(item))
            delete res._child
            this.allGroupList.push(res)
          })
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    // 获取到图片分组时递归处理数据
    dealFnArr(arr, callback = () => {}) {
      arr.map((item) => {
        callback(item)
        if (item._child) {
          this.dealFnArr(item._child, callback)
        }
      })
    },
    // 获取该分类的文件夹和图片
    getClassContent(id, status) {
      console.log(id,status)
      // status 1-> 点击搜索按钮  2->滑动分页查询 3->调整是否只查看图片
      let that = this
      let obj = {
        page: this.page,
        rows: this.rows,
        is_image: 0
      }
      if (this.onlyImage || this.onlyVideo) {
        obj.type = this.onlyImage ? 'image' : 'video'
        obj.is_image = 1
      }
      if (status == 1) {
        // 清空树形结构高亮
        this.nowChooseClassId.id = -2
        this.fatherGroup = [this.nowChooseClassId]
        this.page = 1
      }
      if (status != 2) {
        this.chooseList = []
      }
      // 如果需要查询指定分组
      if (id != -1) {
        obj.group_id = id
        this.clearSearch()
      }
      if (this.searchInfo.keyWord) {
        obj.keywords = this.searchInfo.keyWord
      }
      if (this.searchInfo.time && this.searchInfo.time.length == 2) {
        let arr = []
        let start = Math.ceil(this.searchInfo.time[0].getTime() / 1000)
        let end = Math.ceil(this.searchInfo.time[1].getTime() / 1000)
        if (start == end) {
          end = end + 60 * 60 * 24 - 1
        }
        arr[0] = start
        arr[1] = end
        obj.create_time = arr
      }
      switch (this.sortStyle) {
        case 0:
          obj.order_type = ['create_time', 'desc']
          break
        case 1:
          obj.order_type = ['create_time', 'asc']
          break
        case 2:
          obj.order_type = ['name', 'desc']
          break
        case 3:
          obj.order_type = ['name', 'asc']
          break
        default:
          break
      }
      this.$axios.post(this.$api.user.imgList, obj).then((res) => {
        if (res.code == 0) {
          that.total = res.result.total_number
          that.total_capacity = res.result.total_capacity
          that.use_capacity = res.result.use_capacity
          res.result.list.map((item) => {
            that.$set(item, 'checkflag', that.allChoose)
            that.$set(item, 'updateNameFlag', false)
            that.$set(item, 'updateName', '')
          })
          if (status === 2) {
            // 分页
            that.classContentList.push(...res.result.list)
          } else {
            that.classContentList = res.result.list
          }
          if (that.allChoose) {
            this.chooseList = JSON.parse(JSON.stringify(this.classContentList))
          }
        }
      })
    },
    // 元素滑动到底部搜索下一页
    onLoadNextRow($event) {
      const { scrollTop, clientHeight, scrollHeight } = $event.target
      if (scrollTop + clientHeight === scrollHeight) {
        if (this.page * this.rows <= this.total) {
          this.page++
          if (this.nowChooseClassId.id !== -2) {
            // 树形菜单有高亮
            this.getClassContent(this.nowChooseClassId.id, 2)
          } else {
            this.getClassContent(-1, 2)
          }
        }
      }
    },
    // 单击树形图节点
    clickNodeLabel(a, item) {
      this.nowChooseClassId = JSON.parse(JSON.stringify(item))
      if (this.nowChooseClassId !== item.id) {
        this.fatherGroup = [this.nowChooseClassId]
        this.findFather(this.nowChooseClassId)
      }
      this.page = 1
      this.getClassContent(item.id)
    },
    findFather(value) {
      if (value.id !== -1 || value.id !== -2 || value.id !== 0) {
        if (value.pid) {
          let resItem = this.allGroupList.find((item) => item.id == value.pid)
          this.fatherGroup.unshift(resItem)

          if (resItem && resItem.pid !== 0) {
            this.findFather(resItem)
          }
        }
      }
    },
    // 修改文件或者文件夹名字
    updateName(item, index) {
      if (this.nowItemUpdate) {
        this.sureUpdateName(this.nowItemUpdate)
      }
      if (item.checkflag) {
        item.updateNameFlag = true
        this.$nextTick(() => {
          this.nowItemUpdate = item
          this.$refs.updateInput[index].focus()
        })
      } else {
        item.checkflag = true
        this.changeChooseStatus(item.checkflag, item)
      }
    },
    // 修改选中和修改状态
    checkFlagFn(item, index) {
      if (item.checkflag) {
        item.checkflag = false
        item.updateNameFlag = false
        this.$nextTick(() => {
          this.$refs.updateInput[index].blur()
        })
      } else {
        item.checkflag = true
      }
      this.changeChooseStatus(item.checkflag, item)
    },
    // 双击文件夹
    enterGroup(item) {
      this.findAndOpen(item)
      this.nowChooseClassId = item
      this.getClassContent(item.id)
    },
    // 修改文件名 -> 重命名
    sureUpdateName(item) {
      if (this.isReNameIng) return
      this.isReNameIng = true
      let path = ''
      if (item.updateName == '') {
        this.nowItemUpdate.updateNameFlag = false
        this.nowItemUpdate = null
        this.isReNameIng = false
        return
      }
      item.updateName = item.updateName == '' ? item.name : item.updateName
      if (item.type === 'image') {
        path = this.$api.sourceCenter.UpdateName
      } else if (item.type === 'group') {
        path = this.$api.sourceCenter.rename
      } else if (item.type === 'video') {
        path = this.$api.sourceCenter.UpdateName
      }
      this.$axios
        .post(path, {
          id: item.id,
          name: item.updateName
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('修改成功')
            item.updateNameFlag = false
            item.name = item.updateName
            item.updateName = ''
            if (
              this.nowChooseClassId.id == -1 ||
              this.nowChooseClassId.id == 0 ||
              this.nowChooseClassId.id == -2
            ) {
              if (item.type == 'group') {
                let updateItem = this.classData.find((item1) => item1.id == item.id)
                updateItem.name = item.name
                updateItem.updateName = ''
              }
            } else {
              if (item.type == 'group') {
                this.dealFnArr(this.classData, (item1) => {
                  if (item1.id === item.id) {
                    item1.name = item.name
                  }
                })
              }
            }
          } else {
            this.$message.error(res.msg)
          }
          this.nowItemUpdate.updateNameFlag = false
          this.nowItemUpdate = null
          this.isReNameIng = false
        })
    },
    // 查看原图
    lookCustomaryImage(item) {
      this.lookBigImgInfoFlag = true
      this.lookBigImgInfoIndex = this.imgList.findIndex((item1) => item1.url == item.url)
    },
    // 查看大图时切换图片
    changeImg(status) {
      // 0 -> 上一张 1 -> 下一张
      if (status === 0) {
        this.lookBigImgInfoIndex =
          this.lookBigImgInfoIndex === 0 ? this.imgList.length - 1 : this.lookBigImgInfoIndex - 1
      } else {
        this.lookBigImgInfoIndex =
          this.lookBigImgInfoIndex === this.imgList.length - 1 ? 0 : this.lookBigImgInfoIndex + 1
      }
    },
    // 全选反选
    isAllChoose(val) {
      if (val) {
        this.classContentList.map((item) => (item.checkflag = true))
        this.chooseList = JSON.parse(JSON.stringify(this.classContentList))
      } else {
        this.classContentList.map((item) => (item.checkflag = false))
        this.chooseList = []
      }
    },
    // 每项的选中状态改变
    changeChooseStatus(val, item) {
      if (this.nowItemUpdate) {
        this.sureUpdateName(this.nowItemUpdate)
      }
      if (val) {
        this.chooseList.push(item)
      } else {
        let index = this.chooseList.findIndex((item1) => item1.id == item.id)
        this.chooseList.splice(index, 1)
      }
    },
    // 删除图片
    deleteImage(type, item, index) {
      // type 0 点击图片遮罩删除 1 删除按钮删除
      let imgId = []
      let imgPath = this.$api.sourceCenter.ImageDel
      let groupId = []
      let groupPath = this.$api.sourceCenter.ImagesGroupDel
      let groupArr = { id: [], pid: [] }
      if (!type) {
        if (item.type == 'image') {
          imgId = [item.id]
        }
      } else {
        this.chooseList.map((item1) => {
          if (item1.type == 'group') {
            groupId.push(item1.id)
            groupArr.id.push(item1.id)
            groupArr.pid.push(item1.pid ? item1.pid : 0)
          } else {
            imgId.push(item1.id)
          }
        })
      }

      this.$confirm(`此操作将永久删除该图片`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (imgId.length > 0) {
            this.delInterface(imgPath, imgId, type, index)
          }
          if (groupId.length > 0) {
            this.delInterface(groupPath, groupId, type, index, groupArr)
          }
        })
        .catch(() => {})
    },
    // 删除接口调用
    delInterface(path, id, type, index, groupArr) {
      this.$axios
        .post(path, {
          id
        })
        .then((res) => {
          if (res.code == 0) {
            let str = '文件删除成功'
            if (path == this.$api.sourceCenter.ImagesGroupDel) {
              str = '文件夹删除成功'
              this.dealFnArr(this.classData, (item) => {
                groupArr.pid.map((item1, index1) => {
                  if (item1) {
                    if (item1 === item.id) {
                      let indexF = item._child.findIndex((item3) => item3.id == groupArr.id[index1])
                      item._child.splice(indexF, 1)
                    }
                  } else {
                    let indexF = this.classData.findIndex(
                      (item3) => item3.id == groupArr.id[index1]
                    )
                    if (indexF != -1) {
                      this.classData.splice(indexF, 1)
                    }
                  }
                })
              })
            }
            this.$message.success(str)
            if (!type) {
              this.classContentList.splice(index, 1)
            } else {
              //多个删除
              id.map((item1) => {
                let delIndex = this.classContentList.findIndex((item2) => item2.id == item1)
                this.classContentList.splice(delIndex, 1)
              })
              this.chooseList = []
            }
          }
        })
    },
    // 树型结构删除分组后需要处理的细节
    deleteDeal(item) {
      // 一级分类
      if (!item.pid) {
        let index = this.classData.findIndex((item1) => item1.id === item.id)
        this.classData.splice(index, 1)
      } else {
        // 非一级分类
        this.dealFnArr(this.classData, (item1) => {
          if (item.pid === item1.id) {
            let index = item1._child.findIndex((item2) => item2.id === item.id)
            item1._child.splice(index, 1)
            if (item1._child.length === 0) {
              delete item1._child
            }
          }
        })
      }
      if (item.pid == this.nowChooseClassId.id) {
        // 被删除的文件夹的父级是正在显示的文件夹
        let index = this.classContentList.findIndex((item1) => item1.id === item.id)
        this.classContentList.splice(index, 1)
      } else {
        // 判断被删除的文件夹是否是一级分类
        if (item.pid) {
          this.dealFnArr(this.classData, (item1) => {
            if (item1.id === item.pid) {
              this.nowChooseClassId = item1
            }
          })
        } else {
          this.nowChooseClassId = { id: -1, name: '全部图片' }
        }
        if (this.nowChooseClassId.pid == 0 || this.nowChooseClassId.id == -1) {
          this.fatherGroup = [this.nowChooseClassId]
        } else {
          this.findFather(this.nowChooseClassId)
        }

        this.getClassContent(this.nowChooseClassId.id)
      }
    },
    // 树型结构重命名分组后需要处理的细节
    reNameThenDeal(item) {
      let showItem = this.classContentList.find((item1) => item1.id === item.id)
      if (showItem) {
        showItem.name = item.name
      }
    },
    // 树形结构添加分组后处理细节
    successAddGroup(pid, id, name) {
      if (this.nowChooseClassId.id === pid) {
        if (!this.onlyImage && !this.onlyVideo) {
          if (this.sortStyle === 0) {
            this.classContentList.unshift({
              id,
              name,
              type: 'group',
              checkflag: this.allChoose,
              updateNameFlag: false,
              updateName: '',
              pid
            })
          } else {
            this.classContentList.splice(this.fisrtImgIndex - 1, 0, {
              id,
              name,
              type: 'group',
              checkflag: this.allChoose,
              updateNameFlag: false,
              updateName: '',
              pid
            })
          }
        }
      }
      this.allGroupList.push({
        id,
        name,
        type: 'group',
        checkflag: this.allChoose,
        updateNameFlag: false,
        updateName: name,
        pid
      })
    },
    // 是否只观看图片 或者 排序方式发生改变时
    isLookImageFn(status) {
      if (status == 1) {
        if (this.onlyImage) {
          this.onlyVideo = false
        }
      } else if (status == 2) {
        if (this.onlyVideo) {
          this.onlyImage = false
        }
      }
      this.onlyImage
      this.page = 1
      if (this.nowChooseClassId.id !== -2) {
        // 树形菜单有高亮
        this.getClassContent(this.nowChooseClassId.id, 3)
      } else {
        this.getClassContent(-1, 3)
      }
    },
    // 清空搜索条件
    clearSearch() {
      this.searchInfo = {
        keyWord: '',
        time: []
      }
    },
    // 找出这项的所有父级并展开
    findAndOpen(item) {
      if (item.id === -1 || item.id === 0) return
      // 存储找到的所有父级路径 包括自己
      let allF = []
      allF.unshift(item)
      if (item.pid) this.findAllFather(item, allF)
      this.dealFnArr(this.classData, (val) => {
        let isHave = allF.find((item2) => item2.id === val.id)
        if (isHave) {
          this.$set(val, 'sonIsOpen', true)
        }
      })
    },
    // 递归查找
    findAllFather(item, arr) {
      if (item.pid) {
        let resItem = this.allGroupList.find((item1) => item1.id == item.pid)
        arr.unshift(resItem)
        if (resItem && resItem.pid !== 0) {
          this.findAllFather(resItem, arr)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  .classTree {
    flex: 0 0 240px;
    border: 1px solid #f2f3f5;
    padding: 15px 0 0 15px;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    margin-right: 24px;
  }
  .resources {
    flex: 1;
    display: flex;
    flex-direction: column;
    .resourcesBot {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
      }
      .groupBox {
        height: 166px;
        flex-shrink: 0;
        margin: 0 24px 32px 0;
        user-select: none;
        .groupItem {
          cursor: pointer;
          .top {
            width: 132px;
            height: 132px;
            display: flex;
            background: #f7f8fa;
            border: 1px solid #f2f3f5;
            justify-content: center;
            align-items: center;
          }
          .bot {
            margin-top: 16px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-checkbox {
              margin-right: 8px;
            }
            .groupName {
              font-size: 14px;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            & > input {
              width: 100px;
              border-radius: 4px;
              border: 1px solid #dcdfe6;
              font-size: 14px;
              line-height: 25px;
              padding-left: 10px;
            }
            & > input:focus {
              border: 1px solid #409eff;
            }
          }
        }
      }
      .imageBox {
        .top {
          position: relative;
        }
        .top:hover .mask {
          height: 32px;
        }
        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
        .mask {
          width: 100%;
          height: 0px;
          overflow: hidden;
          background: rgba(51, 51, 51, 0.4);
          color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          i {
            font-size: 16px;
          }
          & > div {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          & > div:hover {
            color: #409eff;
          }
        }
      }
      .videoBox {
        .top {
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          // img {
          //   object-fit: contain;
          // }
          // video {
          //   object-fit: cover;
          // }
        }
      }
    }
    .resourcesTop {
      flex: 0 0 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      .tLeft {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex-shrink: 0;
      }
      .tRight {
        display: flex;
        align-items: center;

        .el-button {
          margin-left: 20px;
        }
        .searchItem {
          display: flex;
          align-items: center;
          font-size: 14px;
          .title {
            width: 100px;
            flex-wrap: nowrap;
            flex-shrink: 0;
            text-align: right;
          }
        }
      }
    }
    .resourcesMid {
      flex: 0 0 48px;
      flex-shrink: 0;
      padding: 0 34px 0 16px;
      background: #f8f9fb;
      margin-bottom: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mLeft {
        display: flex;
        align-items: center;
        font-size: 12px;
        & > div {
          margin-left: 20px;
          line-height: 32px;
        }
        .el-button {
          margin-left: 20px;
        }
      }
      .mRight {
        .el-select {
          width: 140px;
        }
        .el-switch {
          margin-left: 16px;
        }
      }
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  .imgBox {
    width: 1000px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  img {
    user-select: none;
    max-width: 700px;
  }
  i {
    font-size: 50px;
    color: #fff;
    z-index: 10000;
    cursor: pointer;
  }
  // .bg3 {
  //   background-color: rgba(0, 0, 0, 0.5);
  // }
  .close {
    position: absolute;
    top: 100px;
    right: 100px;
  }
  .left {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
  }
  .right {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
  }
}
.addGroupBox {
  text-align: center;
  .showPid {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .title {
      white-space: nowrap;
    }
    .groupRouter {
      display: flex;
      align-items: center;
      flex: 1;
      overflow-x: auto;
      margin: 0 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      .routerItem {
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
      .routerItem:not(:last-child) {
        div:first-child {
          width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .el-input {
    margin-bottom: 20px;
  }
}
.drawerBox {
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  .btn {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
    line-height: 38px;
    text-align: center;
    background-color: #409eff;
    color: #fff;
  }
}
</style>
