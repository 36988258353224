<template>
  <div class="progressItemList">
    <div v-for="(item, index) in showProgressList" :key="index" class="progressItem">
      <div class="left">{{ item.name }}</div>
      <div class="mid">{{ item.size }}</div>
      <div class="right">
        <div v-show="item.error">
          <img src="@/assets/image/uploadImgError.png" alt="" />{{ item.errorDetail }}
        </div>
        <!-- <div v-show="item.percentage === 100"><img src="@/assets/image/uploadImgSuccess.png" alt="" />上传成功</div> -->
        <!-- v-show="item.percentage !== 100 && !item.error" -->
        <div v-show="!item.error">
          <el-progress
            :text-inside="true"
            :stroke-width="12"
            :percentage="item.percentage"
          ></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showProgressList: {
      type: Array,
      default: () => []
    },
    groupId: {
      type: Number,
      default: null
    }
  },
  methods: {
    async launchUpload() {
      for (let index = 0; index < this.showProgressList.length; index++) {
        let formData = new FormData()
        formData.append('files', this.showProgressList[index].file)
        // 设置分组
        if (this.groupId != -1 || this.groupId != -2) {
          formData.append('group_id', this.groupId)
        }
        await this.$axios
          .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
            let { loaded, total } = data
            this.showProgressList[index].percentage =
              Math.floor((loaded / total) * 100) - 10 >= 0
                ? Math.floor((loaded / total) * 100) - 10
                : 0
          })
          .then((res) => {
            if (res.code !== 0) {
              console.log(res, 222)
              this.showProgressList[index].error = true
              this.showProgressList[index].errorDetail = res.msg
            } else {
              this.showProgressList[index].percentage = 100
              if (index === this.showProgressList.length - 1) {
                this.$emit('chooseSuccess')
              }
            }
          })
          .catch((err) => {
            console.log(err, 333)
            this.showProgressList[index].error = true
            this.showProgressList[index].errorDetail = '上传失败'
          })
      }
    }
    // uploadFunction(item, formData) {
    //   return new Promise((resolve, reject) => {
    //     this.$axios
    //       .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
    //         let { loaded, total } = data
    //         item.percentage = Math.floor((loaded / total) * 100)
    //       })
    //       .then((res) => {
    //         if (res.code !== 0) {
    //           resolve(res)
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err, 1111)
    //         reject(err)
    //       })
    //   })
    // }
  }
}
</script>

<style lang="less" scoped>
.progressItemList {
  min-height: 350px;
}
.progressItem {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  .left {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }
  .mid {
    width: 120px;
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-progress {
      width: 60%;
      line-height: 12px;
    }
  }
  &:not(:first-child) {
    padding: 20px 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f2f3f5;
  }
}
</style>
