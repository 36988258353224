<template>
  <el-dialog
    title="上传视频"
    :visible.sync="showVideoDialog"
    width="500px"
    @close="videoDialogClose"
  >
    <div class="uploadVideoBox">
      <div class="uploadItem">
        <div class="title">视频文件：</div>
        <div class="itemContent">
          <div class="videoInput" v-if="!videoUpload.size">
            <i class="el-icon-upload"></i>
            <div>选择视频文件</div>
            <input
              ref="videoInput"
              :multiple="false"
              @input="getUploadvideo($event)"
              type="file"
              accept="video/mp4"
            />
          </div>
          <div class="waitUploadVideo" v-else>
            <div class="title">{{ videoUpload.name }}</div>
            <div class="showPogressBox">
              <el-progress
                type="circle"
                width="60"
                :percentage="videoUpload.percentage"
              ></el-progress>
              <div class="loading" v-if="videoUpload.percentage">
                <div class="uploading" v-if="videoUpload.percentage != 100">
                  <i class="el-icon-loading"></i>上传中
                </div>
                <div class="success" v-else><i class="el-icon-success"></i>上传成功</div>
              </div>
            </div>
            <div class="size">{{ videoUpload.size }}</div>
            <i class="el-icon-error" @click="clearUploadList"></i>
          </div>
          <div class="tips">视频限制在20M以内</div>
        </div>
      </div>
      <div class="uploadItem">
        <div class="title">视频名称：</div>
        <div class="itemContent">
          <el-input placeholder="请输入内容" v-model="videoUpload.name"></el-input>
        </div>
      </div>
      <div class="uploadItem">
        <div class="title">所属分类：</div>
        <div class="itemContent">
          <el-cascader
            v-model="videoUpload.groupClass"
            :options="classData"
            :props="definedProps"
            clearable
          ></el-cascader>
        </div>
      </div>
      <div class="uploadItem">
        <div class="title">视频封面：</div>
        <div class="itemContent">
          <div style="display: flex; align-items: center">
            <div class="imgBox" v-if="videoUpload.cover">
              <img :src="videoUpload.cover" alt="" />
              <i class="el-icon-error" @click="deleteCover"></i>
            </div>
            <div class="videoCover">
              <i class="el-icon-camera"></i>
              <input
                ref="imgInput"
                :multiple="false"
                type="file"
                @input="getImgFile($event)"
                accept="image/png,image/jpeg,image/gif,image/jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showVideoDialog = false">取 消</el-button>
      <el-button type="primary" @click="startUpload">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getfilesize } from '@/util/util.js'
export default {
  props: {
    showVideoDialog: {
      type: Boolean,
      default: false
    },
    classData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      videoName: '',
      videoUpload: {
        name: null,
        percentage: 0,
        size: null,
        file: null,
        groupClass: [-1],
        cover: null
      },
      definedProps: {
        value: 'id',
        label: 'name',
        children: '_child',
        checkStrictly: true
      }
    }
  },
  methods: {
    videoDialogClose() {
      this.clearUploadList(1)
      this.$emit('videoDialogClose')
    },
    // 获取要上传的视频文件
    getUploadvideo($event) {
      this.clearUploadList(1)
      let files = $event.target.files
      for (const key in files) {
        if (!isNaN(Number(key))) {
          let item = files[key]
          console.log(item)
          let maxSize = 1024 * 1024 * 20
          if (item.size > maxSize) {
            this.$message({
              message: `${item.name}超过最大尺寸，已从上传队列删除`,
              type: 'error',
              customClass: 'mzindex'
            })
            continue
          }
          this.videoUpload.name = item.name
          this.videoUpload.size = getfilesize(item.size)
          this.videoUpload.file = item
        }
      }
    },
    // 获取需要上传的视频封面 => 并上传
    getImgFile($event) {
      let file = $event.target.files[0]
      let formData = new FormData()
      formData.append('files', file)
      this.$axios.uploadFile(this.$api.user.addFiles, formData).then((res) => {
        if (res.code == 0) {
          this.videoUpload.cover = res.result.path
        }
      })
    },
    clearUploadList(status) {
      this.videoUpload.name = null
      this.videoUpload.percentage = 0
      this.videoUpload.size = null
      this.videoUpload.file = null
      this.videoUpload.cover = null
      if (!status) this.$refs.videoInput.value = null
    },
    // 点击开始上传视频
    startUpload() {
      if (!this.videoUpload.size) {
        this.$message({
          message: `请上传视频`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      if (this.videoUpload.name == '') {
        this.$message({
          message: `视频名称不能为空`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      if (this.videoUpload.groupClass == null || this.videoUpload.groupClass.length == 0) {
        this.$message({
          message: `请选择视频分类`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      let formData = new FormData()
      formData.append('files', this.videoUpload.file)
      this.$axios
        .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
          let loaded = JSON.stringify(data.loaded)
          let total = JSON.stringify(data.total)
          this.videoUpload.loaded = getfilesize(data.loaded)
          this.videoUpload.percentage = (Math.floor((loaded / total) * 100) - 10) | 0
        })
        .then((res) => {
          if (res.code == 0) {
            this.videoUpload.percentage = 100
            let obj = {
              id: res.result.id,
              nickname: this.videoUpload.name,
              group_id: this.videoUpload.groupClass[this.videoUpload.groupClass.length - 1]
            }
            if (this.videoUpload.cover) {
              obj.cover = this.videoUpload.cover
            }
            return this.$axios.post(this.$api.set.editVideo, obj)
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              customClass: 'mzindex'
            })
          }
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: `成功上传视频`,
              type: 'success',
              customClass: 'mzindex'
            })
            this.$emit('uploadVideoSuccess')
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              customClass: 'mzindex'
            })
          }
        })
    },
    deleteCover() {
      this.videoUpload.cover = ''
      this.$refs.imgInput.value = null
    }
  }
}
</script>

<style lang="less" scoped>
.uploadItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .title {
    line-height: 32px;
    width: 100px;
    white-space: nowrap;
  }
  .itemContent {
    .tips {
      color: #9a9a9f;
      font-size: 12px;
      margin-top: 10px;
    }
    .waitUploadVideo {
      width: 265px;
      height: 150px;
      background: #f4f6fa;
      border-radius: 4px;
      padding: 15px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .showPogressBox {
        display: flex;
        align-items: center;
        .loading {
          i {
            color: #409eff;
            font-size: 16px;
          }
        }
        .success {
          i {
            color: #67c23a;
            font-size: 16px;
          }
        }
      }
      .title {
        font-weight: bold;
      }
      .el-progress {
        width: 60px;
        margin-right: 20px;
      }
      & > i {
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0%;
        transform: translate(50%, -50%);
      }
    }
    .videoInput {
      width: 265px;
      height: 150px;
      border: 1px dashed #e1e1e8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
      i {
        font-size: 28px;
      }
      &:hover {
        border: 1px dashed #96bafa;
        i {
          color: #96bafa;
        }
      }
    }
    .imgBox {
      position: relative;
      margin-right: 20px;
      img {
        width: 98px;
        height: 98px;
        object-fit: contain;
      }
      i {
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    }

    .el-input,
    .el-cascader {
      width: 263px;
    }
    .videoCover {
      width: 100px;
      height: 100px;
      border-radius: 2px;
      border: 1px dashed #e1e1e8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
      i {
        font-size: 28px;
      }
      &:hover {
        border: 1px dashed #96bafa;
        i {
          color: #96bafa;
        }
      }
    }
  }
}
</style>
